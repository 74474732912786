import { PriceFormatter } from "@libs/helper/PriceFormatter";
import DateHelper from "@libs/helper/DateHelper";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Skeleton, Empty, Button, Image, Rate, Popover } from "antd";
import moment from "moment";
import { SlLocationPin } from "react-icons/sl";
import { IoCalendarClearOutline, IoCheckmarkOutline } from "react-icons/io5";
import { AiOutlineInfoCircle } from "react-icons/ai";

const NewOfferList = ({ data, hasFailed, getData, isHome }) => {
  const { t, i18n } = useTranslation("common");
  const isEn = i18n.language === "en";
  const fallbackImg = "/static/images/placeholder/provider.jpg";

  const formatDates = (date) =>
    moment(date, "YYYY-MM-DD").format(isEn ? "DD/MM/YYYY" : "DD.MM.YYYY");
  const priceDisplay = (price, dataCurrency) =>
    PriceFormatter(price, dataCurrency, i18n.language, true);

  const renderDate = (item) => {
    const offerType = item?.date_type;

    if (offerType === "year") {
      return t("offer.text_year_date");
    }

    if (offerType === "seasonal" && item?.dates?.length >= 2) {
      return isEn
        ? `Open for ${item?.dates?.length} seasons`
        : `Geöffnet während ${item?.dates?.length} Saisons`;
    }

    const list = [];
    item?.dates?.map((d, i) => {
      if (i == 0) {
        return list.push(
          t("open_from") +
            DateHelper.dateFormat(d?.start_date) +
            " " +
            t("until") +
            " " +
            DateHelper.dateFormat(d?.end_date)
        );
      } else {
        return list.push(
          t("open_from").toLowerCase() +
            DateHelper.dateFormat(d?.start_date) +
            " " +
            t("until") +
            " " +
            DateHelper.dateFormat(d?.end_date)
        );
      }
    });

    if (list.length > 2) {
      return item?.dates?.length + " " + t("package_date_text");
    }
    return list.join(", " + t("and") + " " + t("opened"));
  };

  const renderOptions = (itemOptions) => {
    const options = isEn
      ? itemOptions?.en || itemOptions?.de || []
      : itemOptions?.de || itemOptions?.en || [];

    return options?.map((item, i) => (
      <div
        key={item + i}
        className="text-grayDark flex flex-row space-x-2 items-top"
      >
        <div className="flex self-center">
          <IoCheckmarkOutline className="text-orange text-2xl" />
        </div>
        <p
          className="w-11/12 text-base"
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
      </div>
    ));
  };

  const properties = {
    duration: 500,
    slidesToShow: 4.3,
    slidesToScroll: 4,
    autoplay: false,
    dots: true,
    infinite: false,
    dotsClass: "slick-dots slick-thumb slick-dots-52",
    customPaging: (i) => {
      return <div className="dot"></div>;
    },
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3.2,
          centerMode: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 2.5,
          centerMode: false,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1.3,
          slidesToScroll: 1,
          centerPadding: 0,
          initialSlide: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          centerPadding: 0,
          slidesToShow: 1.2,
          dots: false,
          arrows: false,

          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      className={`w-full flex flex-col items-center sm:pb-10 ${
        isHome
          ? "mt-[50px] md:mt-[100px] container px-4 md:px-0 min-h-[607px]"
          : ""
      }`}
    >
      {isHome && (
        <h2 className="font-black text-[28px] catamaran md:text-center pb-[24px] md:pb-[30px]">
          {t("frontpage.title_offers")}
        </h2>
      )}

      {data?.length > 0 ? (
        <div className="w-full sm:pb-10 relative">
          <div
            className="absolute h-full hidden sm:flex w-40 bg-gradient-to-l from-white -right-1 top-0 z-10 max-w-md"
            style={{ width: "18rem !important" }}
          ></div>

          <Slider {...properties} className="new-arrow-offer">
            {data?.map((item) => {
              const rate = item.rate?.rate;
              const dataCurrency = item?.basic_room?.price_currency || "EUR";
              const hasDiscount = item?.basic_room?.discount > 0;

              return (
                <a
                  href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}offer/${item?.url_name}`}
                  key={item?.uuid + item?.url_name}
                >
                  <div className="offer-box mx-2">
                    <div className="bg-white shadow rounded-2xl my-2 !h-[540px] relative overflow-hidden">
                      <div className="w-full h-[210px]">
                        <Image
                          src={item?.image_url || fallbackImg}
                          alt={
                            isEn
                              ? item.title?.en ?? item?.title?.de
                              : item?.title?.de ?? item.title?.en
                          }
                          className="object-cover object-center"
                          width="100%"
                          height={210}
                          preview={false}
                          fallback={fallbackImg}
                          loading="lazy"
                        />
                      </div>

                      <div className="py-3 px-4">
                        <h6 className="font-bold text-base line-clamp-2 h-fit">
                          {isEn
                            ? item.title?.en ?? item?.title?.de
                            : item?.title?.de ?? item.title?.en}
                        </h6>
                        <div className="mt-4 flex flex-col space-y-3 pb-4">
                          <div className="d-flex flex-row items-center gap-x-1">
                            <div>
                              <SlLocationPin className="text-[#4dbacb] text-[18px]" />
                            </div>
                            <p className="!text-sm ml-[3px] text-[#000000]">
                              {isEn
                                ? item?.destination?.en ?? item?.destination?.de
                                : item?.destination?.de ??
                                  item?.destination?.en}
                            </p>
                          </div>

                          <div className="d-flex flex-row items-center gap-x-2">
                            <div className="flex pt-[1px]">
                              <IoCalendarClearOutline className="text-[#4dbacb] text-[18px]" />
                            </div>
                            <p className="text-sm text-left text-[#000000] line-clamp-2">
                              {renderDate(item)}
                            </p>
                          </div>
                        </div>
                        <div className="py-2 absolute bottom-2 left-4 right-4 border-t border-black/10">
                          {item.rate?.num_reviews > 0 &&
                          item?.rate?.type == "moverii" ? (
                            <div className="flex flex-col">
                              <div className="flex">
                                <div className="flex">
                                  <Rate
                                    allowHalf
                                    disabled
                                    style={{ fontSize: "18px" }}
                                    className="-mt-1 md:mt-0"
                                    value={
                                      rate >= 1.25 && rate <= 1.749
                                        ? 1.5
                                        : rate >= 1.75 && rate <= 2
                                        ? 2
                                        : rate >= 2.25 && rate <= 2.749
                                        ? 2.5
                                        : rate >= 2.75 && rate <= 3
                                        ? 3
                                        : rate >= 3.25 && rate <= 3.749
                                        ? 3.5
                                        : rate >= 3.75 && rate <= 4
                                        ? 4
                                        : rate >= 4.25 && rate <= 4.749
                                        ? 4.5
                                        : rate >= 4.75 && rate <= 5
                                        ? 5
                                        : rate
                                    }
                                  />
                                </div>
                              </div>
                              <p className="text-sm text-gray-50 break-words">
                                (
                                {isNaN(item.rate?.num_reviews)
                                  ? 0
                                  : item.rate?.num_reviews}
                                ) {t("offer.moverii_traveler_ratings")}
                              </p>
                            </div>
                          ) : (
                            <div className="h-8" />
                          )}

                          <div className="flex flex-col text-sm mt-4 items-start gap-3">
                            <p className={`text-gray-50`}>
                              {t("from")}{" "}
                              <span className="text-black">
                                {item?.basic_room ? (
                                  <>
                                    {item?.basic_room
                                      ?.minimum_price_per_people != "" ? (
                                      <span>
                                        <span
                                          className={`font-bold ${
                                            hasDiscount
                                              ? "line-through relative"
                                              : ""
                                          }`}
                                        >
                                          {priceDisplay(
                                            item?.basic_room
                                              ?.minimum_price_per_people,
                                            dataCurrency
                                          )}
                                        </span>

                                        {hasDiscount && (
                                          <span className="text-[#E43A36] font-bold ml-2">
                                            {priceDisplay(
                                              item?.basic_room
                                                ?.minimum_price_after_discount,
                                              dataCurrency
                                            )}
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      "0"
                                    )}
                                  </>
                                ) : (
                                  "0"
                                )}
                              </span>
                              <p className="text-gray-50">
                                {t("offer.for")}{" "}
                                <span className="font-bold text-black">
                                  {item?.basic_room?.duration || "0"}
                                </span>{" "}
                                {t("dayss")}
                              </p>
                            </p>

                            {(item?.options?.en?.length > 0 ||
                              item?.options?.de?.length > 0) && (
                              <div className="text-xs text-gray-50 flex flex-row space-x-1 items-center">
                                <Popover
                                  overlayClassName="popover-transparent arrow-greenblue bg-lightBlueGreen rounded-lg pt-2 pb-0"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  content={
                                    <div className="flex flex-col bg-lightBlueGreen !p-4 pb-5 rounded-t-sm rounded-b-xl shadow-md w-90">
                                      <p className="font-bold -mt-2 text-base">
                                        {t("search.included_price_popover")}
                                      </p>
                                      <div className="flex flex-col space-y-2 mt-3">
                                        {renderOptions(item?.options)}
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                >
                                  <div className="flex gap-x-1">
                                    <AiOutlineInfoCircle className="text-base text-black" />
                                    <p className="text-black">
                                      {t("offer.Included_in_the_price")}
                                    </p>
                                  </div>
                                </Popover>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </Slider>
        </div>
      ) : hasFailed ? (
        <div className="w-full h-[500px] flex flex-col items-center justify-center gap-3">
          <Empty />
          <Button
            className="!bg-orange !text-white !border-none text-base font-bold px-6 md:px-12 w-full md:w-fit rounded-3xl h-[44px]"
            onClick={() => getData()}
          >
            Try again!
          </Button>
        </div>
      ) : (
        <div className="w-full flex items-center overflow-hidden gap-5 px-4 md:px-0 mt-5">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <Skeleton.Button
              key={"offer-placeholder-skeleton-" + i}
              active
              className="!h-[500px] !w-[250px] md:!w-[260px] !rounded-3xl"
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default NewOfferList;
