import CustomImage from "@components/CustomImage/CustomImage";
import classNames from "classnames";
import { GoArrowRight } from "react-icons/go";

export default function Comminity({
  className,
  title,
  description,
  isHome,
  link,
  textLink,
  icon,
}) {
  return (
    <section className="flex w-full lg:basis-1/3 flex-col sm:flex-row lg:flex-col items-center sm:items-start lg:items-center justify-start lg:px-2 gap-y-4 sm:gap-7 lg:gap-0">
      <div
        className={classNames(
          "w-fit h-fit rounded-full flex items-center justify-center",
          className
        )}
      >
        <CustomImage
          src={`static/global/community/${icon}.svg`}
          alt={title}
          loading={isHome ? "eager" : "lazy"}
          width={61}
          height={61}
        />
      </div>
      <div className="flex flex-col items-center sm:items-start lg:items-center gap-3 w-full lg:w-auto">
        <h5 className="font-bold text-[22px]">{title}</h5>
        <p className="text-center sm:text-left lg:text-center lg:w-auto px-2 sm:px-0 lg:px-2 text-[16px]  h-18">
          {description}
        </p>
        {!isHome && (
          <a
            href={link}
            className="flex flex-row space-x-4 items-center main-color"
          >
            <span className="text-base font-medium">{textLink}</span>
            <GoArrowRight className="text-xl -mb-[1px]" />
          </a>
        )}
      </div>
    </section>
  );
}
