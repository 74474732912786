import CustomImage from "@components/CustomImage/CustomImage";
import EmailForm from "./EmailForm";
import { observer } from "mobx-react";

const Newsletter = observer(
  ({
    title,
    description,
    inputPlaceholder,
    buttonTitle,
    removeContainer,
    customClass,
    isOnHomePage,
  }) => {
    return (
      <div
        className={`w-full mx-auto mb-[75px] lg:mt-[100px] ${
          !removeContainer && "container"
        } ${customClass} ${
          isOnHomePage ? "" : "bg-[#E1F9FC] md:bg-transparent py-10 md:py-0"
        }`}
      >
        <div className="grid basis-3/6 grid-col-1 md:grid-cols-2 w-full h-full items-center justify-center gap-5 md:gap-0">
          <div className="col-span-1 w-full lg:w-full h-[450px] hidden md:flex">
            <div className="w-full h-full md:py-0 scale-75 sm:scale-90 md:scale-75 lg:scale-95 relative flex justify-center">
              <span className="absolute z-30 w-[200px] md:h-[150px] lg:h-[183px] top-[20px] md:top-[10px] lg:top-[20px] -left-[30px] lg:left-[0px]">
                <CustomImage
                  src="static/global/newsletter/newsletter-1.webp"
                  alt="moverii newsletter 1"
                  urlWidth={300}
                  className="object-center object-cover rounded-[20px] shadow-md"
                  format="webp"
                  fallback="/static/images/placeholder/provider.jpg"
                />
              </span>
              <span className="z-10 md:w-[250px] lg:w-[338px] h-[410px]">
                <CustomImage
                  src="static/global/newsletter/newsletter-2.webp"
                  alt="moverii newsletter 2"
                  width={338}
                  urlWidth={600}
                  className="object-center object-cover rounded-[20px] shadow-md"
                  format="webp"
                  fallback="/static/images/placeholder/provider.jpg"
                />
              </span>
              <span className="absolute z-30 bottom-1 w-[170px] h-[132px] -right-[30px] sm:right-[10px]">
                <CustomImage
                  src="static/global/newsletter/newsletter-3.webp"
                  alt="moverii newsletter 3"
                  width={170}
                  urlWidth={300}
                  className="object-center object-cover rounded-[20px] shadow-md"
                  format="webp"
                  fallback="/static/images/placeholder/provider.jpg"
                />
              </span>
            </div>
          </div>

          <div className="col-span-1 h-full w-full flex justify-center items-center">
            <div className="flex flex-col justify-center px-4 xl:pt-0 xl:px-0 max-w-sm lg:max-w-[70%]">
              {title && (
                <h3
                  className={`text-[#2B2D42] text-[1.56rem] mb-4 ${
                    isOnHomePage
                      ? "font-black text-left md:text-center"
                      : "font-bold md:text-left text-center"
                  }`}
                >
                  {title}
                </h3>
              )}
              <p
                className={`text-[#2B2D42] text-base pb-4 ${
                  isOnHomePage
                    ? "text-left md:text-center"
                    : "md:text-left text-center"
                }`}
              >
                {description}
              </p>
              <EmailForm
                inputPlaceholder={inputPlaceholder}
                buttonTitle={buttonTitle}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Newsletter;
