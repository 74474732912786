import Comminity from "./comminity";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export default function Sectioncomminity({
  className = "",
  mode = "white",
  classMain = "pb-0",
  isHome,
  removeContainer,
}) {
  const { t } = useTranslation("common");

  return (
    <section
      className={classNames(
        `relative mx-auto  md:mt-0  flex  justify-between ${
          !removeContainer && "container"
        }`,
        classMain
      )}
    >
      <div className="flex flex-col lg:flex-row w-full items-start justify-between lg:gap-y-0 gap-y-8 gap-4 md:gap-8 lg:gap-0">
        <Comminity
          link="/s/moverii"
          className={className}
          title={t("main.active_dream_vacation")}
          description={t("main.des_comminity_one")}
          textLink={t("go_tripFinder")}
          isHome={isHome}
          icon={mode === "white" ? "like_white" : "like_orage"}
        />
        <Comminity
          link="/content/cancellation"
          className={className}
          title={t("main.flexible_bookings")}
          description={t("main.des_comminity_two")}
          textLink={t("sec2_more")}
          isHome={isHome}
          icon={mode === "white" ? "start_white" : "start_orange"}
        />
        <Comminity
          link="/about-us"
          className={className}
          title={t("main.authentic_experiences")}
          description={t("main.des_comminity_three")}
          textLink={t("sec3_more")}
          isHome={isHome}
          icon={mode == "white" ? "heart_white" : "heart_orange"}
        />
      </div>
    </section>
  );
}
